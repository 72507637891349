/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `auth` key is used for marking an item on the Sidenav that require authentication to access the link.
  9. The `permissions` key is used for marking an item on the Sidenav that require specified roles to access the link.
  10. The `route` key is used for the "path" parameter of <Route> component.
  11. The `link` key is used for the "to" parameter of <Link> component.
  12. The `href` key is used to store the external links location.
  13. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  14. The `component` key is used to store the component of its route.
*/

import { Navigate } from "react-router-dom";

import AccountProfile from "pages/account/manage/AccountProfile";
import SignIn from "pages/authentication/signin";
import SignUp from "pages/authentication/signup";
import VerifyEmail from "pages/authentication/verifyemail";
import ResetPassword from "pages/authentication/resetpassword";

import NewUser from "pages/settings/user/new/NewUser";
import ManageUsers from "pages/settings/users/manage/ManageUsers";
import ManageRoles from "pages/settings/roles/manage/ManageRoles";

import SettingsIcon from "@mui/icons-material/Settings";

import MDAvatar from "components/atoms/MDAvatar";
import { Permission } from "models/abac";

const navigate = (displayName, displayNameKey) => {
  return [
    {
      name: displayName,
      key: displayNameKey,
      auth: true,
      //permissions: Permission.VIEW_MEMBER_DASHBOARD,
      link: `/account/profile`,
      route: `/account/profile`,
      component: <AccountProfile />,
      icon: (
        <MDAvatar bgColor="info" size="sm">
          {displayName?.charAt(0).toUpperCase()}
        </MDAvatar>
      ),
    },
    {
      type: "divider",
      key: "divider-action",
    },
    {
      type: "title",
      title: "Action",
      key: "title-action",
      permissions: Permission.VIEW_ADMIN_DASHBOARD,
    },
    {
      type: "divider",
      key: "divider-settings",
      auth: true,
      permissions: [
        Permission.VIEW_ADMIN_DASHBOARD,
        Permission.VIEW_SUPERADMIN_DASHBOARD,
      ],
    },
    {
      type: "title",
      title: "Settings",
      key: "title-settings",
      auth: true,
      permissions: [
        Permission.VIEW_ADMIN_DASHBOARD,
        Permission.VIEW_SUPERADMIN_DASHBOARD,
      ],
    },
    {
      name: "New User",
      key: "new-user",
      auth: true,
      permissions: [
        Permission.VIEW_ADMIN_DASHBOARD,
        Permission.VIEW_SUPERADMIN_DASHBOARD,
      ],
      icon: <SettingsIcon />,
      route: "/settings/user",
      link: "/settings/user",
      component: <NewUser />,
    },
    {
      name: "Users",
      key: "manage-users",
      auth: true,
      permissions: [
        Permission.VIEW_ADMIN_DASHBOARD,
        Permission.VIEW_SUPERADMIN_DASHBOARD,
      ],
      icon: <SettingsIcon />,
      route: "/settings/users",
      link: "/settings/users",
      component: <ManageUsers />,
    },
    {
      name: "Roles",
      key: "manage-roles",
      auth: true,
      permissions: Permission.VIEW_SUPERADMIN_DASHBOARD,
      icon: <SettingsIcon />,
      route: "/settings/roles",
      link: "/settings/roles",
      component: <ManageRoles />,
    },
    {
      name: "Sign In",
      key: "signin",
      route: "/authentication/signin",
      component: <SignIn />,
    },
    {
      name: "Sign Up",
      key: "sign-up",
      // auth: true,
      // permissions: Permission.PERFORM_SUPERADMIN_ACTION,
      route: "/authentication/signup",
      component: <SignUp />,
    },
    {
      name: "Reset Password",
      key: "reset-password",
      route: "/authentication/resetpassword",
      component: <ResetPassword />,
    },
    {
      name: "Verify Email",
      key: "verify-email",
      auth: true,
      route: "/authentication/verifyemail",
      component: <VerifyEmail />,
    },
    {
      name: "All",
      key: "all",
      auth: true,
      permissions: Permission.VIEW_MEMBER_DASHBOARD,
      route: "*",
      component: <Navigate to={`/account/profile`} />,
    },
  ];
};

export default navigate;
